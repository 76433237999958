import React, { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import axios from '../../api/axios';
import Cookies from 'universal-cookie';
import useAuth from './hooks/useAuth';
import { Oval } from 'react-loader-spinner';
import Header from '../Header';
const cookies = new Cookies()
const Login = () => {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const { setAuth } = useAuth();
    const navigate = useNavigate();
    const location = useLocation();
    const [errMsg, setErrMsg] = useState('');
    const [loader, setLoader] = useState(false)
    const from = location?.state?.from?.pathname || "/";
    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoader(true)
        try {
            const response = await axios.post(
                "login/",
                { username, password },
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                    withCredentials: true,
                }
            );
            window.localStorage.setItem("isLoggedIn", "true")
            window.localStorage.setItem("username", username)
            window.localStorage.setItem("access", response?.data?.token)
            cookies.set('access', response?.data?.token);
            const accessToken = response?.data?.token;
            setAuth({ username, password, accessToken });
            setLoader(false)
            navigate(from, { replace: true });
        } catch (err) {
            setErrMsg(err.response?.data?.error)
            setLoader(false)
        }
    };
    return (
        <main className="app">
            <Header name="Automatic Item Generator v.5 (AIG-5)"/>
            <div className="col-lg-7 mx-auto p-5 logIn">
                {errMsg && <div className="alert alert-danger alert-dismissible fade show" role="alert" style={{ paddingBottom: "0.1rem" }}>
                    <p className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive" style={{ color: "black" }}>{errMsg}</p>
                    <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={() => setErrMsg("")}></button>
                </div>}
                <form className="form-outline form-white mb-4" onSubmit={handleSubmit}>
                    <div>
                        <label className="form-label" htmlFor="email" style={{ color: "black" }}>
                            Username:
                        </label>
                        <br />
                        <input
                            type="text"
                            id="username"
                            autoComplete="off"
                            className="form-control "
                            name="username"
                            value={username}
                            placeholder="Enter your email"
                            onChange={(e) => setUsername(e.target.value)}
                            required
                        />
                    </div>
                    <div>
                        <br />
                        <label className="form-label" htmlFor="password" style={{ color: "black" }}>
                            Password:
                        </label>
                        <br />
                        <input
                            type="password"
                            className="form-control"
                            name="password"
                            value={password}
                            id="password"
                            placeholder="*******"
                            onChange={(e) => setPassword(e.target.value)}
                            autoComplete="new-password"
                            required
                        />
                    </div>
                    <div>
                        <button
                            className="btn btn-primary  px-5 my-3"
                            type="submit"
                            style={{ background: "#0171bd", border: "1px solid #0171bd" }}
                            disabled={loader}
                        >
                            {loader ? <Oval
                                height="25"
                                width="25"
                                radius={5}
                                color="black"
                                ariaLabel="puff-loading"
                                wrapperStyle={{}}
                                wrapperClass=""
                                visible={true}
                            /> : "Login"}
                        </button>
                    </div>
                </form>
            </div>
        </main>
    )
}

export default Login
